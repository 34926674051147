<template>
  <div class="header-page">
    <div class="title">
      <span v-if="hasBackLink" v-html="NavBack" @click="$router.go(-1)"></span>
      {{ title }} <span class="second-title" v-if="secontTitle">{{ secontTitle }}</span>
    </div>
    <div class="user-name">
      <div class="text-block">
        <div class="line1">{{ currentUser }}</div>
        <div class="line2">{{ userRole }}</div>
      </div>
      <div
        class="icon-block"
        @click="isOpenUserMenu = true"
        v-html="Settings"
      ></div>
    </div>
    <ul
      class="user-menu"
      v-if="isOpenUserMenu"
      v-click-outside="onCloseUserMenu"
    >
      <li @click="myProfile">My profile</li>
      <li @click="exit">Exit</li>
    </ul>
  </div>
</template>

<script>
import { Settings, NavBack } from '@/utils/icons'
import vClickOutside from 'click-outside-vue3'

export default {
  name: 'HeaderPage',
  directives: { clickOutside: vClickOutside.directive },
  props: {
    title: { default: '', type: String },
    secontTitle: { default: '', type: String },
    hasBackLink: { default: false, type: Boolean },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser?.user_data?.email
    },

    userRole() {
      return this.$store.state.currentUser?.user_data?.role
    },
  },

  data() {
    return {
      Settings,
      NavBack,
      isOpenUserMenu: false,
    }
  },

  methods: {
    onCloseUserMenu() {
      this.isOpenUserMenu = false
    },

    myProfile() {
      this.$router.push('/my-profile')
    },

    exit() {
      localStorage.removeItem('userAuth')
      this.$router.push('/login')
    },
  },
}
</script>
